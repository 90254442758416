
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Open+Sans:wght@400;700&display=swap');

body{
    background: url("../../assets/images/Mask Group 47.png");
    background-color: #00000029;
    font-family: 'Poppins', sans-serif;
}

body.modal-open #root {
    filter: blur(8px);
    -webkit-filter: blur(8px);
}

.login-form{
    max-width: 300px;
    margin-left: 50px;
    font-family: 'Poppins, Bold';
}

.common-color{
    color: 	#0054A6;
}

.common-black-color{
    color: #000000;
}
.common-bg{
    background-color: #0054A6;
}

._btn {
    padding: 0.4rem 1rem;
    border-radius: 5px;
}

._theme_btn {
    background-color: #0054A6;
    border: 1px solid blue;
    color: #fff;
}

._theme_btn:hover {
    background-color: #044c94;
    border: 1px solid rgb(5, 5, 219);
    color: #fff;
}

.login-btn{
    padding: 9px 130px;
    border: 1px solid #0054A6;
    border-radius: 50px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);

}
.white-text{
    color: white;
}
.pointer{
    cursor: pointer;
}
.login-box{
    max-width: 400px;
    border-radius: 10px;
    border: 1px solid white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    margin-left: 20px;
    padding: 70px 0px;
    font-size: 12px;
    background-color: #FFFFFF;

}
.max-width{
    max-width: 100%;
}
.left-margin-30{
    margin-left: 30px;
}
.left-margin-10{
    margin-left: 10px;
}
.left-margin{
    margin-left: 100px;
}
.banner-content{
    position: relative;
    bottom: 150px;
    left: 150px;
}
.top-margin{
    margin-top: 70px;
}
.bold-font{
    font-family: Poppins, Bold;
    font-weight: bolder;
}
.semibold-font{
    font-family:  Poppins, SemiBoldBold;
    font-weight: bolder;
}
.bold-weight{
    font-weight: bold;

}
.smaller-padding{
    padding-top: 2px;
}
.medium-font{
    font-family: Poppins, Medium;
    font-size: 14px;
}
.regular-font{
    font-family: Poppins, Regular;
    font-size: 14px;
}
.light-italic-font{
    font-family: Poppins, Light Italic;
}
.light-font{
    font-family: Poppins, Light ;
}
.input-border{
    border-radius: 50px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);


}
.symbol-icon{
    position: relative;
    bottom: 30px;
    left: 25px
}


.show-btn{
    position: relative;
    bottom: 30px;
    left: 245px;
    border: none;
    background-color: white;
}
.padding-left{
    padding-left: 50px;
}

.error-message {
    color: red;
    font-size: 0.9rem;
}

.font-14px{
    font-size: 14px;
}
.font-light{
    font-family: 'Poppins, Light';
}
.sidebar{
    width:300px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);

}
.sidebar-logo{
    padding: 10px 30px;
    background-color: #00000014;

}
.sidebar-menus{
    font-family: Poppins, Regular;
    padding-bottom: 30px;

}
.grey-text{
    color: #6E6E6E;
}
.change-placeholder::placeholder {
    color: #0054A6

}

.ul{
    list-style-type: none;
}
.main-div{
    margin-left: 150px;
    display: flex;
    flex-direction: row;
}

.main-display {
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 0;
    width: calc(100% - 300px);
    padding: 10px 15px;
    top: 0;
}

.page_wrapper {
    margin-top: 90px;
}

.margin-left-200{
    margin-left: 200px;



}
.margin-left-150{
    margin-left: 150px;
}
.pb-logo{
    padding: 15px;
    background-color: white;
    border-radius: 5px;
    width: 100px;
}
.name-div{
    margin-left: auto;
    margin-top: 60px;
    margin-right: 150px;
    display: flex;
    flex-direction: row;

}
.top-margin-40{
    margin-top: 42px;
}
.name-container{
    margin-left: auto;
    margin-top: 60px;
    display: flex;
    flex-direction: row;

}
.header-container{
    margin-left: auto;
    margin-top: 30px;
    z-index: 999;

}
.name-box{
    background-color: white;
    padding: 5px 20px;
    border-radius: 5px;
    height: 35px;
}
.desc-box{
    background-color: #0054A6;
    padding: 30px;
    border-radius: 20px;

}
.box-shadow{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);


}

.task-box{
    background-color: white;
    border-radius: 10px;
    padding: 30px;
    height: 500px;


}
.feedback-box{
    background-color: white;
    border-radius: 10px;
    padding: 30px;
    height:550px;

}
.chart-box{
    background-color: white;
    border-radius: 10px;
    padding: 30px;
    

}
.bell-icon{
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    margin-left: 10px;
}
.link{
    text-decoration: none;
}
.add-btn{
    background-color: #0054A6;
    border-radius: 5px;
    border: 1px solid blue;
    padding: 7px;
    padding: 5px 15px;
    min-width:150px;
    cursor: pointer;
}
.add-modal-btn{
    background-color: #0054A6;
    border-radius: 5px;
    border: 1px solid blue;

}
.common-btn{
    background-color: #0054A6;
    border-radius: 5px;
    border: 1px solid blue;

}

.btn-primary {
    background-color: #0054A6 !important;
    border: 1px solid blue !important;
}

.search {
    color: #524d4d;
    border: 1px solid gainsboro;
    padding: 5px 30px;
    border-radius: 5px;
}

.modal-box{
    padding: 0px 20px 20px 20px;
}
.btn-padding{
    padding:  8px 194px;
}
.btn-padding2{
    padding:  8px 145px;
}
.add-btn-padding{
    padding: 8px 197px;
}
.update-btn-padding{
    padding: 8px 185px;
}

.display{
    border-bottom: white;
}
.smaller-font{
    font-size: 12px;
}
.font-size-18{
    font-size: 18px;
}

.table{
    background-color: white;
    margin-top: 20px;
    border-radius: 5px;
}

.dlt-icon{
    margin-left: 10px;
    cursor: pointer;
}
.text-align-center{
    text-align: center;
}
.confirm-btn{
    background-color: #0054A6;
    border-radius: 5px;
    border: 1px solid blue;
    padding: 5px 15px;

}

.cancel-btn{
    color: #0054A6;
    border: 1px solid #0054A6;
    background-color: white;
    border-radius: 5px;
}

.circular{
    border-radius: 50%;
}
.ql-snow * {
    white-space: initial;
}
.white-spaces {
    white-space: initial !important;
  }


input:focus::-webkit-contacts-auto-fill-button{
    opacity: 0;
}
.container-box{
    margin-left: 300px;
    width: calc(100% - 300px);
    padding: 15px;
    position: relative;
}
.flex{
    display: flex;
    flex-direction: row;
}
.grid-container{
    display: grid;
  gap: 20px 50px;
  grid-template-columns: auto auto auto auto;
}
.auto-left-margin{
    margin-left: auto;
}
.view-btn{
    color: #0054A6;
    border: none;
    background: none;
}
.accept-btn{
    background-color: #2BC48A;
    border: 1px solid #2BC48A;
    border-radius: 50px;
    padding: 0px 8px;
}
.decline-btn{
    color: #FF8585;
    background: none;
    border: none;
    padding-left: 10px;
}
.dropdown-btn{
    border: 1px solid white;
    background-color: white;
}
.left-margin-20{
    margin-left: 20px;
}
.logout-box{
    border: 1px solid white;
    background-color: white;
    padding-left: 16px;
}
.welcome{
    position: absolute;
    left: 135px;
    top: -56px;
}
.simple-btn{
    background-color: white;
    border: none;
}

.search_part {
    margin-bottom: 20px;
}

.recipe-box {
    background-color: white;
    border: 1px solid white;
    border-radius: 8px;
    padding: 10px;
    height: 340px;
    position: relative;
    padding-bottom: 35px;
}

._recipe_icon_wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 10px;
}

.border-radius-5{
    border-radius: 5px;
}
.tab-box{
    background-color: white;
    max-width: 100%;
}
.add-product-box{
    padding: 40px;
}
.product-box{
    max-width: 45%;
    margin-bottom: 20px;


}
.chef-box{
    max-width: 45%;
    margin-top: 20px;

}
.feature-box{
    max-width: 30%;
    margin-bottom: 20px;


}

.image-box{
    width: 160px;
    height: 170px;
    border-radius: 5px;
    border: 1px solid gainsboro;
    position: relative;
}
.icon-preview-box{
    width: 150px;
    height: 150px;
    border-radius: 5px;
    border: 1px solid gainsboro;
    text-align: center;
    padding-top: 15px;
}
.save-btn{
    padding: 7px 75px !important;
}
.next-btn{
    padding: 7px 200px !important;
}
.next-cancel-btn{
    padding: 7px 190px !important;
}
.save-bigger-btn{
    padding: 7px 277px;
}
.save-bigger-cancel-btn{
    padding: 7px 265px;
}
.desc-input{
    height: 150px;
    text-align: left;
}

.img-preview{
    width: 160px;
    height: 170px;
    border: 1px solid black;
    margin-top: 0px !important;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #0054A6;
    background-color: var(--bs-nav-tabs-link-active-bg);
    border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-link {
    color: #868686;
}
.camera-img{
    margin-top: 40px;
    margin-left: 60px;
   

}
.feedback-card{
    background-color: white;
    padding: 10px;
    border-radius: 10px;

}
.padding-x{
    padding: 0px 20px;
}
.other-tab-padding {
    padding: 30px;
}
.upload-btn{
    background-color: white;
    border: 1px solid white;
}
.loader_data {
    margin: auto;
    text-align: center;
  }
  
  .loader_data svg {
    width: 100px;
    height: 100px;
  }
  .border-radius{
    border-radius: 10px ;
  }
 
  .add-product-position{
    position: relative;
    left: 120px;
  }
  .td-2{
    width: 300px;
  }
 .search-icon{
    position: relative;
    left: 25px;
    bottom: 2px;
    z-index: 9999;
 }
 .shape{
    background-color: #2AA805;
    padding: 5px 10px;
   
 }
  .arrows{
    display: inline-grid;
    margin-left: 10px;
 } 
 .pointer{
    cursor:pointer

 }
 .rsw-editor{
    height: 40vh;
}

.ql-editor p {
    font-size: 16px;
}
.fixed{
    position: fixed;
    overflow-y: scroll;
    top: 0;
    bottom: 0;

}
.bar{
    margin-top: 60px;
}
.modal-90w{
    max-width: 70%;
}
.close{
    text-align: right;
    margin: 10px;
}
.switch.btn-lg{
    width: 250px;
}
.name-width{
    width:200px;
}
.action-width{
    width:100px;
}
.recipe-name-width{
    width: 120px;
}
.responsive {
    width: 85%;
    height: auto;
  }
  .align-center{
    align-items: center;
  }
  .margin-top-mac{
    margin-top: 0px;
  }

  ._tb-btn{
    width: 40px;
    font-size: 1rem;
}

.t_data{
    width: 100px !important;
}
#error-page{
    position: absolute;
    top: 10%;
    left: 15%;
    right: 15%;
    bottom: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.1);
}
#error-page .error-content{
    max-width:600px;

  text-align: center;
}

.add-btn.white-text.btn-sm._tb-btn.mr-0{
    margin-right: 0px !important;
}

.nutri_info p{
    margin :0.5rem 0.4688rem;
   }
   .row_view {
       display: flex;
       align-items: center;
       justify-content: space-between;
       font-size: 1rem
   }
   .border-b{
       border-bottom: 1px solid #0054a6;
       margin-bottom: 10px;
   }
   .row_view.heading p:nth-child(1){
       font-weight: bold;
   }
   .t-r{
       text-align: right;
   }
   .inner-heading {
       margin-left: 16px;
   }
   .big-text{
       font-size:1.125rem
   }
   .big-header p:nth-child(1){
       font-size: 1.25rem;font-weight: bold;
   }
   .big-header p:nth-child(2){
       font-size: 2rem;font-weight: bold;
   }
   .row_view.big-header.border-b {
       align-items: flex-end;
   }
   .preparation_parent ol li{
    font-size: 16px;
    padding-bottom: 0.4688rem;
   }
   .preparation_parent ol{
        padding-left: 1rem;
    
   }
   .preparation_parent ::marker {
    color: #0054a6;
    font-weight: 600
   }
   .product-heading{
    color: #0054a6;
    font-size: 2rem !important;
    padding: 0 0 1.25rem;
    position: relative;
    letter-spacing: 0.0625rem;
    line-height: 1.25;
    font-size: 1.5625rem;
    margin-bottom: 0.9375rem
   }
   .product-heading::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 4.6875rem;
    height: 0.125rem;
    border-bottom: 0.125rem solid currentColor;
}
.head_desc{
    padding-bottom: 0.9375rem;
    font-size: 1.125rem; 
}
.head_desc span{
    color: #0054a6;
}

.features_outer_parent .image-box {
    width: 42%;
    height: auto;
    border-radius: 5px;
    border: 1px solid gainsboro;
    padding: 0;
}
.features_outer_parent .img-preview {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: none;
}
.features_parent ul{
    list-style-type: none;padding-left: 0rem;
}
.features_parent ul li .tick-icon{
    margin-right: 10px;
    width: 12px;
    height: 13px;
}
.color-blue{
    color:#0054a6
}
.active{
    color: #0054a6
}
.recipe_ingredient .row_view.heading p {
    font-size: 1.125rem;
    padding: 0.9375rem 0;
    font-weight: 500 !important;
    margin-bottom: 0;
}
.headng {
    padding: 0.9375rem 0 0.4688rem;
    font-weight: bold;
    font-size: 1.125rem;
    margin-bottom: 0;
}
.th_width{
    width: 80px;
}
.table-scroll {
    display: block;
    overflow-x: scroll;
    white-space: nowrap;
}

.main-screen {
    bottom: 40px;
    position: relative;
}

/* .main-screen{
    margin-top: 20px;
} */

.no_data {
    margin: auto;
    text-align: center;
  }
  
  .no_data svg {
    width: 100px;
    height: 100px;
  }
  
  .no_data p {
    margin: 0;
    font-weight: 600;
    color: #0054A6;
  }
  .message_width{
    width:220px;
  }
  .margin-auto{
    margin: 0px auto;
  }
  

  .spiner{
    width: 20px;
    height: 20px;
}
 ._ml_10{
  margin-left: 10px;
 }

 .image-box-product {
    width: 160px;
    height: 170px;
    display: block;
    border-radius: 5px;
    border: 1px solid gainsboro;
    margin: auto;
    margin-top: 1.8rem;
    position: relative;
}


/* -------new code start-------- */
.grid-container {
    display: flex;
    flex-wrap: wrap;gap: 0;
}

.grid-container > div {
    width: calc(100% / 5 - 15px);
    margin-right: 15px;
    width: calc(100% / 5 - 12px);
    margin-bottom: 15px;
}

.grid-container > div:nth-child(5n) {
    margin-right: 0;
}
.recipe-box .text-align-center img{
    width: 100%;
    object-fit: cover;
}
.cm_main_container {
    display: flex;
    padding: 0 35px;
    overflow: hidden;
}
.cm_sidebar{
width: 300px;
}
.cm_right-container {
    width: calc(100% - 300px);
    padding-left: 35px;
}
.cm_main_container .sidebar.left-margin-50.fixed {
    margin-left: 0;
}
.cm_right-container .container-box {
    margin-left: 0px;
    margin-right: 0px;
}
.top-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.search_part h4 {
    margin-right: 167px;
}

._recipe_heading{
        font-size: 1rem;
        font-weight: 500;
    }

.created_by_recipe {
        font-size: 0.9rem;
    }  


.not-found-wrapper{
    width: 100%;
    display: block;
    background: #fff;
    padding: 1rem;
    border-radius: 5px;
}
.dimension{
    margin-top: 10px;
    margin-left: 10px;

}
/* Cropper */

.App {
    text-align: center;
  }
  .container {
    display: block;
    margin-top: 5rem;
  }
  
  .header{
    display: block;
  }
  
  .cropButton{
    position: absolute;
    top:60%;
    left: 45%;
  }
  .font-black{
    color: black !important;
  }

  .lang_select {
    min-width: 250px;
}

.inner_page {
    background: #fff;
    margin-top: 15px;
    border-radius: 10px;
    padding: 15px;
}

.image-upload2 {
    width: 160px;
    height: 170px;
    display: block;
    border-radius: 5px;
    border: 1px solid gainsboro;
    margin: auto;
    margin-top: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.image-upload input ,
.image-upload2 input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 9;
    cursor: pointer;
    font-size: 0;
    opacity: 0;
}
  

.cm_err {
    color: red;
    font-size: 14px;
    margin-bottom: 10px;
}


.ul_btn {
    display: block;
    white-space: nowrap;
    background: #bbb;
    color: #000;
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 4px;
    margin-top: 9px;
}

.ul_btn.btn_sm {
    padding: 0px 8px;
    margin-top: 0px;
    font-size: 14px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;

}

.ul_btn.btn_sm .dlt-icon {
    margin-left: 0px;
}

.ul_btn small {
    display: inline-block;
    margin-left: 10px;
}

@media screen and (max-width: 1520px) {
    .grid-container > div {
        width: calc(100% / 4 - 12px);
    }

    .grid-container > div:nth-child(4n) {
        margin-right: 0;
    }

    .grid-container > div:nth-child(5n) {
        margin-right: 15px;
    }
}

/* Media query */

@media screen and (max-width: 1440px) {
    .grid-container > div {
        width: calc(100% / 3 - 10px);
    }

    .grid-container > div:nth-child(3n) {
        margin-right: 0;
    }

    .grid-container > div:nth-child(4n) {
        margin-right: 15px;
    }

    
    .next-btn{
            padding: 7px 115px !important;
        }  
        .next-cancel-btn{
            padding: 7px 105px !important;
        }  
        .search_part h4 {
            margin-right: 40px;
        }

        .save-bigger-btn{
            padding: 7px 163px;
        }
        .save-bigger-cancel-btn{
            padding: 7px 150px;
        }
        .top-margin-40{
            margin-top: 20px;
        }
        .left-margin{
            margin-left: 50px;
        }
        .responsive {
            width: 72%;
            height: auto;
          }
          .top-margin{
            margin-top: 0px;
        }
        .align-center{
            align-items: flex-start;
            
        }
        .margin-top-mac{
            margin-top: 60px;
        }
        .add-btn{
            background-color: #0054A6;
            border-radius: 5px;
            border: 1px solid blue;
            padding: 7px;
            padding: 5px 15px;
        }
        .width-150{
            width: 100px;
        }
        
}
.th-width{
    width: 200px ;
}

@media screen and (max-width: 1100px){
    .search_part h4 {
        margin-right: 40px;
    }

    .grid-container > div {
        width: calc(100% / 2 - 8px);
    }

    .grid-container > div:nth-child(2n) {
        margin-right: 0;
    }

    .grid-container > div:nth-child(3n) {
        margin-right: 15px;
    }
}



.product-spinner {
    width: 15px;
    height: 15px;
    margin-left: 5px;
    margin-right: 5px;

}

.page_box {
    background: #fff;
    padding: 40px;
}

.page_box_title {
    font-weight: 600;
    color: #1b54a7;
    font-size: 24px;
    margin-bottom: 30px;
}

.cm_file_upload {
    border: 1px solid #dcdcdc;
    border-radius: 5px;
    height: 170px;
    margin: 0px auto;
    position: relative;
    width: 160px;
}

.cm_file_upload > input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    font-size: 0;
    opacity: 0;
    z-index: 9;
    cursor: pointer;
}

.cm_uploaded_file {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
}

.cm_upload_icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 35px;
}

.cm_hash {
    color: red;
}

.form-group {
    margin-bottom: 30px;
}

.cm_add_more_div {
    width: 200px;
    margin-left: 10px;
    text-align: right;
}
.cm_add_more_div .add-btn {
    margin-left: 0 !important;
}
.sidebar-menus li {
    position: relative;
    cursor: pointer;
}

.down_errow {
    position: absolute;
    width: 7px;
    height: 7px;
    right: 13px;
    top: 7px;
    transform: rotate(45deg);
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
}


.sidebar-menus a {
    text-decoration: none;
    color: #6e6e6e;
}

.sidebar-menus a.active{
    color: #0054a6;
}

.sidebar-menus span img,
.sidebar-menus a img {
    margin-right: 10px;
}

.down_arrow {
    position: absolute;
    width: 7px;
    height: 7px;
    right: 13px;
    top: 7px;
    transform: rotate(45deg);
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
}
.up_arrow {
    position: absolute;
    width: 7px;
    height: 7px;
    right: 13px;
    top: 7px;
    transform: rotate(-135deg);
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
}

.sidebar-menus li ul {
    list-style: none;
    margin: 0;
}

.sidebar-menus li ul li {
    margin-top: 20px;
    padding-left: 20px;
}

.c_ingredient {
    background: #ebebeb;
    padding: 20px;
    margin-top: 20px;
    border-radius: 8px;
}

.cm_multi_select_box {
    width: 100%;
    max-width: 100% !important;
}

.cm_edit_icon {
    position: absolute;
    right: 2px;
    top: 2px;
    background: white;
    padding: 5px;
    border-radius: 4px;
}

.recipe_white > span {
    display: inline-block;
    width: 150px;
    margin-bottom: 10px;
}

.view_banner {
    max-width: 100%;
    margin-bottom: 30px;
}

.d_s_c_ul {
    margin: 0;
    padding: 0;
    list-style: none;
    padding-left: 60px;
}

.d_s_c_ul li {
    margin-bottom: 30px;
}

ul.d_s_c_ul li {
    margin-bottom: 30px;
}



.switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  
  .switch-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 70px;
    height: 30px;
    background: grey;
    border-radius: 100px;
    position: relative;
    transition: background-color .2s;
  }
  
  .switch-label .switch-button {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 26px;
    height: 26px;
    border-radius: 45px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  }
  
  .switch-checkbox:checked + .switch-label .switch-button {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
  
  .switch-label:active .switch-button {
    width: 60px;
  }

  .cm_swich_abs {
    position: absolute;
    right: 30px;
    top: 0;
}

.cake_design_card {
    background: #fff;
    min-height: 200px;
    margin-bottom: 30px;
    display: flex;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
}

.cake_design_banners {
    width: 200px;
}

.cake_design_banners img {
    width: 200px;
    height: 200px;
    object-fit: cover;
}

.cake_design_contents {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.cake_design_contents h5 {
    font-size: 16px;
    font-weight: 600;

}

.cake_design_contents p {
    font-size: 14px;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
}

.cale_design_cat {
    font-size: 12px;
    background: #50a30c;
    color: #fff;
    font-weight: 600;
    padding: 4px 10px;
    border-radius: 20px;
    margin-bottom: 10px;
    display: inline-block;
    margin-right: 10px;
}

.cake_design_actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dash_card_top {
    background-color: #0054a6;
    border-radius: 8px;
    padding: 15px;
    color: #fff;
    margin-bottom: 24px;
    padding-left: 110px;
    position: relative;
}

.dash_card_top img {
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
}

.dash_card_top h5 {font-size: 28px;font-weight: 600;margin: 0;}

.dash_card_top p {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
}

.chart_wrap {
    background: #fff;
    padding: 15px;
    border-radius: 8px;
}

._feedback_box {
    border: 1px solid #e0e0e0;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 8px;
}

.cm_pagination .pagination {
    justify-content: center;
    margin: 0;
    padding: 0 15px;
}

.cm_pagination .pagination li {
    margin-left: 5px;
}


.cm_pagination .pagination li a {
    display: block;
    width: 28px;
    height: 28px;
    background: #e5e5e5;
    text-align: center;
    line-height: 28px;
    color: #1b54a7;
    border-radius: 4px;
    font-size: 14px;
    text-decoration: none;
}

.cm_pagination .pagination li.disabled a {
    background: #f7f7f7;
    color: #c5c5c5;
    cursor: not-allowed;
}

.cm_pagination .pagination li.active a, .cm_pagination .pagination li a:hover {
    background: #1b54a7;
    color: #fff;
}

.desgin_banner {
    display: inline-block;
    width: 160px;
    height: 120px;
    margin-bottom: 24px;
    margin-right: 24px;
    border: 1px solid #dee1e5;
    vertical-align: top;
    position: relative;
    border-radius: 8px;
}

.desgin_banner > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 7px;
}

.desgin_banner span {
    position: absolute;
    right: -13px;
    top: -13px;
    width: 24px;
    height: 24px;
    background: #ebbaba;
    border-radius: 100%;
    text-align: center;
    line-height: 22px;
    cursor: pointer;
}

.template_box {
    width: 400px;
    display: inline-block;
    height: 270px;
    margin: 15px;
}

.template_box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}

.second_step {
    margin: 40px auto;
    width: 1000px;
}

.second_step .active_banner {
    width: 100%;
    user-select: none;
}

.draggable_wrapper {
    position: relative;
}

img.dragImg {
    position: absolute;
}


.konvajs-content {
    height: calc(100vh - 350px) !important;
}

.app_download_count {
    background-color: white;
    border-radius: 10px;
    padding: 30px;
    display: flex;
    align-items: center;
    min-width: 250px;
    justify-content: space-between;
}

.app_download_count img {
    max-width: 50px;
    max-height: 50px;
}

.app_download_count h4 {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    color: #1b54a7;
}

.months_list {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.months_list span {
    padding: 5px 10px;
    background: #d6d6d7;
    font-size: 13px;
    border-radius: 4px;
    margin-right: 10px;
    min-width: 80px;
    text-align: center;
    font-weight: 500;
    cursor: pointer;
    position: relative;
}

.months_list span b {
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    text-align: center;
    color: #0054a7;
    display: none;
}

.months_list span:last-child {
    margin-right: 0;
}


.months_list span.diseble {
    background: #f7f7f7;
    color: #c2c2c2;
    cursor: no-drop;
}

.months_list span.active {
    background: #0054a6 !important;
    color: #fff !important;
}

.months_list span.active b {
    display: block;
}


.dash_tabs span {
    display: inline-block;
    padding: 10px 15px;
    background: #ebebeb;
    margin-right: 15px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    color: grey;
    cursor: pointer;
}

.dash_tabs {
    margin-top: 25px;
}

.dash_tabs span.active {
    background: #0133a0;
    color: #fff;
}

.dashboard_count {
    background-color: #ffffff;
    border-radius: 4px;
    padding: 20px 10px;
    color: #0133a0;
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .04);
    margin-top: 20px;
    position: relative;
    padding-left: 90px;
}

.dashboard_count2 {
    background-color: #ffffff;
    border-radius: 4px;
    padding: 20px 10px;
    color: #0133a0;
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .04);
    margin-top: 20px;
    position: relative;
}

.dashboard_count:before {
    content: '';
    position: absolute;
    width: 80px;
    height: 100%;
    background: #f7f7f7;
    left: 0;
    top: 0;
    border-radius: 4px 0 0 4px;
}

.dashboard_count2 img,
.dashboard_count img {
    position: absolute;
    left: 28px;
    top: 50%;
    transform: translateY(-50%);
    filter: invert(38%);
}

.info_icon {
    position: absolute;
    right: 20px;
    top: 10px;
    cursor: pointer;
}

.info_icon2 {
    position: absolute;
    right: 20px;
    top: 10px;
    cursor: pointer;
}

.dashboard_count2 img,
.dashboard_count img {
    max-width: 25px;
}

.chart_container {
    background: #fff;
    padding: 30px;
    border-radius: 10px;
}

.chart_container {
    background: #fff;
    padding: 30px;
    border-radius: 10px;
}

.total_card {
    background: #fff;
    padding: 30px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.total_card h2 {
    margin: 0;
    font-weight: 600;
}

.total_card h5 {
    margin: 0;
    color: #a8a8a8;
}

.no_table {
    padding: 20px !important;
    font-size: 18px;
    font-weight: 600;
    color: #bababa !important;
    text-align: center;
}

.FIE_text-tool-options > div:last-child,
.FIE_text-italic-option,
.FIE_text-bold-option,
.FIE_tools-items .FIE_carousel-item:nth-child(3),
.FIE_tools-items .FIE_carousel-item:nth-child(4),
.FIE_tools-items .FIE_carousel-item:nth-child(5),
.FIE_tools-items .FIE_carousel-item:nth-child(6),
.FIE_tools-items .FIE_carousel-item:nth-child(7),
.FIE_tools-items .FIE_carousel-item:nth-child(8),
.FIE_tabs .FIE_tab:nth-child(1) {
    display: none !important;
}

.screen_time {
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    margin-top: 20px;
}

.screen_time ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.screen_time ul li {
    border-bottom: 1px solid #e3e3e3;
    padding: 15px 0;
}

.screen_time ul li span {
    display: inline-block;
    min-width: 300px;
}

.screen_time ul li:last-child {
    border-bottom: 0 !important;
}

.view_by_dates {
    position: absolute;
    background: #fff;
    color: #6e6e6e;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 4px;
    left: 10px;
    top: 10px;
    cursor: pointer;
}

.cm_res_err {
    text-align: center;
    color: red;
    margin: 0;
    margin-top: 30px;
}

.dashboard_count h5 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0;
    margin-bottom: 15px;
}

.dashboard_count h6 {
    font-size: 14px;
    margin: 0;
}

img.cm_info {
    left: auto !important;
    right: -10px !important;
    transform: none !important;
    top: -1px !important;
}

.cm_retaition_box {
    background-color: #ffffff;
    border-radius: 4px;
    padding: 20px 10px;
    color: #0133a0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .04);
    margin-top: 50px;
}

.range_group {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.page_loader img {
    width: 100px;
}

.page_loader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
}

b.status_loader {
    position: absolute;
    right: -63px;
    font-size: 14px;
    font-weight: 400;
    top: 3px;
}


@media (max-width: 768px) {
    .konvajs-content canvas {
        width: 100% !important;
        height: auto !important;
    }

    .second_step {
        width: 100%;
        margin: 0px auto;
    }
}

.final_banner {
    position: fixed;
    width: 100%;
    background: white;
    top: 0;
    left: 0;
    z-index: 9999;
    overflow: auto;
    height: 100vh;
}

img.final_banner_img {
    max-width: 100%;
}

.final_banner .container {
    padding: 0 !important;
}

.final_banner h3 {
    font-size: 18px;
    padding: 15px;
    font-weight: 700;
}

.final_banner small {
    color: #0054A6;
}

.cm_social_share {
    text-align: center;
    margin-top: 20px;
}

.cm_social_share button {
    margin-left: 15px;
    margin-bottom: 50px;
}

.cm_notif {
    position: fixed;
    top: 20px;
    right: 0;
    background: #0054a6;
    padding: 8px 15px;
    width: 250px;
    border-radius: 10px 0 0 10px;
    color: #fff;
    font-size: 14px;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
}

.cm_upload_report_btn {
    position: relative;
}

.cm_toast {
    position: fixed;
    padding: 10px 15px;
    background: #237c00;
    right: 0;
    top: 20px;
    z-index: 99999;
    min-width: 350px;
    border-radius: 8px 0 0 8px;
    font-size: 14px;
    border: 1px solid #b1ff97;
    color: #fff;
    border-right: 0;
}



.cm_upload_report_btn input {position: absolute;left: 0;top: 0;width: 100%;height: 100%;opacity: 0;z-index: 9;font-size: 0px;cursor: pointer;}

img.table_user_img {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 100%;
    margin-right: 10px;
}

.profile_img img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 100%;
    margin: 0 auto;
    display: block;
    border: 2px solid #dee2e8;
    margin-bottom: 30px;
}

.custom_select > ul {
    margin: 0;
    list-style: none;
    height: 300px;
    overflow: auto;
    position: absolute;
    background: #fff;
    border: 1px solid #dde2e5;
    padding: 15px;
    border-radius: 8px;
    left: 0;
    top: 37px;
    width: 100%;
}

.custom_select {
    position: relative;
}

.custom_select > ul li {
    padding: 8px 20px;
    border-radius: 4px;
}

.custom_select > ul li:hover {
    background: #dde2e5;
    cursor: pointer;
}

/* .full_box {
    background: #0133a0;
    color: #fff;
    text-align: center;
}

.other_box {
    background: #fff;
    text-align: center;
}

.full_box h2 {
    font-size: 42px;
    margin-top: 60px;
}

.other_box.w-100 {
    text-align: center;
}

.cm_b_grid {
    border-bottom: 1px solid #000;
}

.cm_r_grid {
    border-right: 1px solid #000;
}


.box_with_b_l {
    background-color: #ffffff;
    padding: 20px 10px;
    text-align: center;
    margin-top: 20px;
    position: relative;
    border-left: 4px solid #0133a0;
} */


.city_card_wrapper {
    background: #fff;
    padding: 30px;
    border-radius: 10px;
}

.city_card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 5px 0;
    position: relative;
}

.city_user_line {
    position: absolute;
    width: 100%;
    height: 2px;
    background: #dddddd;
    bottom: 2px;
}

.city_user_line_active {
    position: absolute;
    height: 100%;
    background: green;
    left: 0;
    top: 0;
    width: 50%;
}

.user_line_chart {
    background: #fff;
    padding: 30px;
    border-radius: 10px;
}